import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { sanitizeData } from '../../core/components/FormComponent';
import { FormHandles } from '@unform/core';
import { useParams } from 'react-router-dom';
import sendMail from './Modules/SendSaleMail';
import { useConfiguration } from '../../../../hooks/Configuration';
import SaleStats from './Modules/SaleStats';
import ContactModule from './Modules/ContactModule';
import CreateMedicSubscription from './Modules/CreateMedicSubscription';
import { FaMoneyCheck } from 'react-icons/fa';
import { useAuth } from '../../../../hooks/Auth';
import { FinanceReportModule } from './Modules/CheckFinance';
import visualizarRecibo from './Modules/VisualizarRecibo';
import RemoveRecursivePayment from './Modules/RemoveRecursivePayment';
import createnewPix from './Modules/CreateNewPix';
interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

const callback = (string, form: React.RefObject<FormHandles>) => {
  const installments = form?.current?.getFieldValue('installment') || 1;

  const discount =
    sanitizeData.currencyInput(form?.current?.getFieldValue('discount')) || 0;

  const total =
    sanitizeData.currencyInput(form?.current?.getFieldValue('total')) || 0;

  const installmentValue = parseInt(
    ((total - discount) / installments).toString(),
    10,
  );

  form?.current?.setFieldValue('installment_value', installmentValue);
};

const Sale: React.FC = () => {
  const endpoint = '/sales';
  const title = 'vendas';
  const { user } = useAuth();
  const formSchema = {
    client_id: {
      alias: 'client_id',
      model: 'selectApi',
      name: 'client_id',
      label: 'Cliente',
      endpoint,
    },

    category: { model: 'input', type: 'text', name: 'category', label: 'Categoria' },
    area: { model: 'input', type: 'text', name: 'area', label: 'Área' },
    subarea: { model: 'input', type: 'text', name: 'subarea', label: 'Subárea' },
    fields: {
      model: 'jsonList',
      type: 'text',
      name: 'fields',
      label: 'Campos',
      list: [
        { label: 'Requisição', name: 'requisicao', type: 'input' },

      ],
    },
    fields_text: { model: 'input', type: 'text', name: 'fields_text', label: 'Campos adicionais' },

    product_id: {
      alias: 'product_id',
      model: 'selectApi',
      name: 'product_id',
      label: 'Produto',
      defaultValue: 1,
      endpoint,
    },

    payment_status_id: {
      model: 'selectSimple',
      name: 'payment_status_id',
      label: 'Status de pagamento',
      defaultValue: 'paid',
      options: [
        { label: 'Aguardando', value: 'waiting' },
        { label: 'Pago', value: 'paid' },
        { label: 'Cancelado', value: 'cancelled' },
        { label: 'Aguardando análise do Antifraude', value: 'verificating' },

      ]

    },


    payment_method_id: {
      model: 'selectSimple',
      name: 'payment_method_id',
      label: 'Método de pagamento',
      defaultValue: 'cortesy',
      options: [
        { label: 'Depósito', value: 'deposit' },
        { label: 'Boleto', value: 'boleto' },
        { label: 'Cartão', value: 'credit_card' },
        { label: 'Pix', value: 'pix' },
        { label: 'Cortesia', value: 'cortesy' },
      ]

    },
    sale_hash: {
      model: 'input',
      name: 'sale_hash',
      label: 'Hash',
      defaultValue: '',
      callback,
    },
    total: {
      model: 'currencyInput',
      name: 'total',
      label: 'Total',
      defaultValue: '0',
      callback,
    },
    discount: {
      model: 'currencyInput',
      name: 'discount',
      label: 'Desconto',
      defaultValue: '0',
      callback,
    },
    installment: {
      model: 'selectSimple',
      name: 'installment',
      label: 'Nº de Parcelas',
      defaultValue: '1',
      options: [
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 },
        { label: '4', value: 4 },
        { label: '5', value: 5 },
        { label: '6', value: 6 },
        { label: '7', value: 7 },
        { label: '8', value: 8 },
        { label: '9', value: 9 },
        { label: '10', value: 10 },
        { label: '11', value: 11 },
        { label: '12', value: 12 },
      ],
      callback,
    },
    installment_value: {
      model: 'currencyInput',
      name: 'installment_value',
      label: 'Valor da parcela',
      defaultValue: '0',
      readOnly: true,
    },

    transaction_id: {
      model: 'input',
      name: 'transaction_id',
      label: 'ID da transação',
      defaultValue: '',

    },


    courses: {
      model: 'JsonInput',
      endpoint: `/courses`,
      name: 'courses',
      label: 'Cursos',
    },
  };

  const formValidation: Record<string, ValidationSchema> = {

  };


  const { projectHash, eventHash } = useConfiguration();

  const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'Protocolo', show: false },
    project_id: {
      ref: 'project_id',
      column: 'project_id',
      label: 'Projeto',

    },
    approved_at: {
      ref: 'approved_at',
      column: 'approved_at',
      label: 'Data de Aprovação',
      mask: 'date',
    },
    event_id: {
      ref: 'event_id',
      column: 'event_id',
      label: 'Evento',
      show: false
    },
    invoice_number: {
      ref: 'invoice_number',
      column: 'invoice_number',
      label: 'Nº de invoices',
      show: false

    },
    client_id: {
      ref: 'client_id',
      column: 'client_id_response',
      includeColumn: 'name',
      label: 'Cliente',
    },
    phone: {
      ref: 'phone',
      column: 'phone',
      label: 'Telefone',
      show: false,
    },
    sale_hash: {
      ref: 'sale_hash',
      column: 'sale_hash',
      label: 'Hash',
      show: false,
    },
    installment_value: {
      ref: 'installment_value',
      column: 'installment_value',
      label: 'Valor da Parcela',
      mask: 'currency',
      show: false,
    },
    payment_status: {
      ref: 'payment_status_id',
      column: 'payment_status_id',
      label: 'Status',
    },
    payment_method: {
      ref: 'payment_method_id',
      column: 'payment_method_id',

      label: 'Forma de Pagamento',
    },
    installment: {
      ref: 'installment',
      column: 'installment',
      label: 'Nº de Parcelas',
    },
    total: { ref: 'total', column: 'total', label: '(=)Total (Bruto)', mask: 'currency' },
    fees: { ref: 'fees', column: 'fees', label: '(-)Taxa Cliente', mask: 'currency' },
    tax: { ref: 'tax', column: 'tax', label: '(-) Taxa Empresa', mask: 'currency' },
    transaction_tax: { ref: 'transaction_tax', column: 'transaction_tax', label: '(-) Taxa de transação', mask: 'currency' },
    adminstration_tax: { ref: 'adminstration_tax', column: 'adminstration_tax', label: '(-) Taxa adm.', mask: 'currency' },
    net_total: { ref: 'net_total', column: 'net_total', label: '(=)Total (Líquido)', mask: 'currency' },

    product: {
      ref: 'product_id',
      column: 'product_id_response',
      includeColumn: 'title',
      label: 'Produto',
    },

    category: {
      ref: 'category',
      column: 'category',
      label: 'Categoria',

    },
    area: {
      ref: 'area',
      column: 'area',
      label: 'Área',

    },
    subarea: {
      ref: 'subarea',
      column: 'subarea',
      label: 'Subárea',

    },

    fields_text: {
      ref: 'fields_text',
      column: 'fields_text',
      label: 'Campos adicionais',

    },

    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };


  const formSchemaPayment = {
    tax: {
      model: 'currencyInput',
      name: 'tax',
      label: 'Taxa Fixa',
      defaultValue: '0',
      callback,
    },
    fees: {
      model: 'currencyInput',
      name: 'fees',
      label: 'Taxas do cliente',
      defaultValue: '0',
      callback,
    },
    administration_tax: {
      model: 'currencyInput',
      name: 'administration_tax',
      label: 'Taxa de transação',
      defaultValue: '0',
      callback,
    },
    transaction_tax: {
      model: 'currencyInput',
      name: 'transaction_tax',
      label: 'Taxa de transação',
      defaultValue: '0',
      callback,
    },
    net_total: {
      model: 'currencyInput',
      name: 'net_total',
      label: 'Total Líquido',
      defaultValue: '0',
      callback,
    },
    total: {
      model: 'currencyInput',
      name: 'total',
      label: 'Total',
      defaultValue: '0',
      callback,
    },
    discount: {
      model: 'currencyInput',
      name: 'discount',
      label: 'Desconto',
      defaultValue: '0',
      callback,
    },
    installment: {
      model: 'selectSimple',
      name: 'installment',
      label: 'Nº de Parcelas',
      defaultValue: '1',
      options: [
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 },
        { label: '4', value: 4 },
        { label: '5', value: 5 },
        { label: '6', value: 6 },
        { label: '7', value: 7 },
        { label: '8', value: 8 },
        { label: '9', value: 9 },
        { label: '10', value: 10 },
        { label: '11', value: 11 },
        { label: '12', value: 12 },
      ],
      callback,
    },
    installment_value: {
      model: 'currencyInput',
      name: 'installment_value',
      label: 'Valor da parcela',
      defaultValue: '0',
      readOnly: true,
    },


    courses: {
      model: 'JsonInput',
      endpoint: `/courses`,
      name: 'courses',
      label: 'Cursos',
    },
  };

  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
      () =>
        <FinanceReportModule />,
    ],
    bodyModules: user.email === 'guilherme@encontrodigital.com.br' ?
      [
        (lineData: Record<string, any>) =>
          lineModules.updateUnique({
            lineData,
            title,
            formSchema,
            validationSchema: {},
            endpoint,
          }),
        (lineData: Record<string, any>) =>
          lineModules.updateUnique({
            lineData,
            title,
            formSchema: formSchemaPayment,
            validationSchema: {},
            endpoint,
            Icon: FaMoneyCheck
          }),


        (lineData: Record<string, any>) =>
          lineModules.delete({
            lineData,
            title,
            formSchema,
            validationSchema: formValidation,
            endpoint,
          }),
        (lineData: Record<string, any>) =>
          sendMail({
            lineData,

          }),
        (lineData: Record<string, any>) =>
          ContactModule({
            lineData

          }),
        (lineData: Record<string, any>) =>
          visualizarRecibo({
            lineData

          }),
        (lineData: Record<string, any>) =>
          CreateMedicSubscription({
            lineData

          }),
        (lineData: Record<string, any>) =>
          RemoveRecursivePayment({
            lineData

          }),
        (lineData: Record<string, any>) =>
          createnewPix({
            lineData

          }),

      ] : [
        (lineData: Record<string, any>) =>
          lineModules.updateUnique({
            lineData,
            title,
            formSchema,
            validationSchema: {},
            endpoint,
          }),
        (lineData: Record<string, any>) =>
          lineModules.delete({
            lineData,
            title,
            formSchema,
            validationSchema: formValidation,
            endpoint,
          }),
        (lineData: Record<string, any>) =>
          sendMail({
            lineData,

          }),
        (lineData: Record<string, any>) =>
          ContactModule({
            lineData

          }),
        (lineData: Record<string, any>) =>
          visualizarRecibo({
            lineData

          }),
        (lineData: Record<string, any>) =>
          CreateMedicSubscription({
            lineData

          }),
        (lineData: Record<string, any>) =>
          RemoveRecursivePayment({
            lineData

          }),



      ],
  };

  return (
    <Container>
      <SaleStats />
      <SearchComponent {...config} />
    </Container>
  );
};

export default Sale;
