import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { useField, FormHandles } from '@unform/core';
import { Container, ContainerSelectActive } from './styles';
import { Error } from '../styles';
interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  setCallback?: Function;
  formRef?: React.RefObject<FormHandles>;
}

const SelectAction: React.FC<InputProps> = ({
  name,
  icon: Icon,
  formRef,
  setCallback = () => { },
  ...rest
}) => {
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState(defaultValue && typeof defaultValue === 'string' ? defaultValue : 'yes');



  useEffect(() => {
    registerField({
      name: fieldName, ref: inputRef.current,
      path: undefined, getValue: () => value, setValue: (ref, value) => setValue(value)
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    setValue(defaultValue && typeof defaultValue === 'string' ? defaultValue : 'yes')
  }, [defaultValue])

  return (
    <aside style={{ width: '70px' }}



    >
      <span>{rest.placeholder}</span>
      {Icon && <Icon />}
      <ContainerSelectActive status={value} onClick={(e) => {
        const newValue = value === 'yes' ? 'no' : 'yes';

        setValue(newValue);
        setCallback(newValue);


      }}>
        <nav className={`SelectActionInput-${value}`} />
      </ContainerSelectActive>

      {error && (
        <Error title={error}>
          <FiAlertCircle />
        </Error>
      )}
    </aside>
  );
};

export default SelectAction;
