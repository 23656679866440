import React from 'react';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';

import { useAuth } from '../../../../../../hooks/Auth';
import { FiSend } from 'react-icons/fi';
import Whatsapp from '../../../../../../components/Whatsapp';
import { authTitle } from '../../../../../../config';
import { FaFilePdf, FaMoneyBill, FaMoneyBillAlt } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import downloadit from '../../../../../../utils/download';
import { string } from 'yup';
import handleFinanceReportPDF from './handleFinanceReportPDF';

interface ICreateModule {
  lineData: Record<string, any>;
}

export const FinanceReportByProjectModule: React.FC<ICreateModule> = ({ lineData, }) => {


  const { addToast } = useToast();




  const gerarRelatorioFinanceiro = async (operation) => {

    addToast({ title: 'Gerando relatório', type: 'info' });

    await downloadit({ url: `/check-saldo/${lineData.url}/${operation}` });


  }

  const gerarRelatorioFinanceiroPDF = async (operation) => {

    addToast({ title: 'Gerando relatório', type: 'info' });

    const project = await api.get(`/projects/${lineData._id}`)

    const response = await api.get(`/check-saldo/${lineData.url}/json`);

    handleFinanceReportPDF({ data: response?.data, project: project?.data })


  }

  const gerarRelatorioFinanceiroFaturasPDF = async (operation) => {

    addToast({ title: 'Gerando relatório', type: 'info' });

    const project = await api.get(`/projects/${lineData._id}`)

    const response = await api.get(`/check-saldo/${project.data.url}/faturas/SaleInvoice/json`);

    handleFinanceReportPDF({ data: response?.data, project: project?.data })


  }

  const moduleUpdate: React.FC<ICreateModule> = ({ lineData }): JSX.Element => (
    <>


    </>
  );

  return <> <button title="Relatório Financeiro em PDF com resumo" className="lineIcon" type="button" onClick={() => gerarRelatorioFinanceiroPDF('')}>
    <FaFilePdf />
  </button>

    <button title="Relatório Financeiro em PDF das faturas" className="lineIcon" type="button" onClick={() => gerarRelatorioFinanceiroFaturasPDF('')}>
      <FaFilePdf color='blue' />
    </button>
  </>

}
