import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';
import contactModule from './Modules/ContactModule';
import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../../../hooks/Configuration';
import { useAuth } from '../../../../hooks/Auth';
import sendPasswordAvaliator from './Modules/EnviarPassword';
import sendPasswordUser from './Modules/EnviarPasswordUser';
interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}


const Dashboard: React.FC = () => {
  const endpoint = '/students';
  const title = 'Cadastrados';
  const { projectHash, eventHash } = useConfiguration();
  const { user } = useAuth();
  const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },
    project_id: { ref: 'project_id', column: 'project_id', label: 'Projeto', show: false },
    event_id: { ref: 'event_id', column: 'event_id', label: 'Evento', show: false },
    phone: { ref: 'phone', column: 'phone', label: 'Telefone', show: false },
    profile: { ref: 'profile', column: 'profile', label: 'Perfil', show: false },
    name: { ref: 'name', column: 'name', label: 'Nome' },
    email: { ref: 'email', column: 'email', label: 'Email' },

    document_number: {
      ref: 'document_number',
      column: 'document_number',
      label: 'CPF',
    },

    state: {
      ref: 'state',
      column: 'state',
      label: 'Estado',
    },
    country: {
      ref: 'country',
      column: 'country',
      label: 'País',
    },


    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema: Record<string, any> = {
    project_id: { model: 'input', type: 'text', name: 'project_id', label: 'Projeto' },

    name: { model: 'input', type: 'text', name: 'name', label: 'Nome' },
    social_name: { model: 'input', type: 'text', name: 'social_name', label: 'Nome social' },
    email: { model: 'input', type: 'text', name: 'email', label: 'Email' },
    gender_id: {
      model: 'selectSimple',
      name: 'gender_id',
      label: 'Gênero',
      defaultValue: '2',
      options: [
        { label: 'Masculino', value: 'Masculino' },
        { label: 'Feminino', value: 'Feminino' },
        { label: 'Prefiro não informar', value: 'Prefiro não informar' },

      ],
    },

    zipcode: { model: 'input', type: 'text', name: 'zipcode', label: 'CEP' },
    address_type: { model: 'input', type: 'text', name: 'address_type', label: 'Tipo de logradouro' },
    address: { model: 'input', type: 'text', name: 'address', label: 'Endereço' },
    address_number: { model: 'input', type: 'text', name: 'address_number', label: 'Número' },

    neighborhood: { model: 'input', type: 'text', name: 'neighborhood', label: 'Bairro' },
    city: { model: 'input', type: 'text', name: 'city', label: 'Cidade' },
    state: { model: 'input', type: 'text', name: 'state', label: 'Estado' },
    country: { model: 'input', type: 'text', name: 'country', label: 'País' },


    etnia: { model: 'input', type: 'text', name: 'etnia', label: 'Etnia' },
    birth_date: { model: 'datePicker', type: 'text', name: 'birth_date', label: 'Data de Nascimento' },
    phone: { model: 'input', type: 'text', name: 'phone', label: 'Telefone' },
    document_number: { model: 'input', type: 'text', name: 'document_number', label: 'CPF' },

    mother_name: { model: 'input', type: 'text', name: 'mother_name', label: 'Nome da Mãe' },
    father_name: { model: 'input', type: 'text', name: 'father_name', label: 'Nome do Pai' },

    rg: { model: 'input', type: 'text', name: 'rg', label: 'Carteira de Identidade (RG)' },
    rg_date: { model: 'input', type: 'text', name: 'rg_date', label: 'Data de expedição do RG' },
    rg_emissor: { model: 'input', type: 'text', name: 'rg_emissor', label: 'Órgão Expedidor do RG' },
    rg_uf: { model: 'input', type: 'text', name: 'rg_uf', label: 'Estado Expedidor do RG ' },
    voter_registration: { model: 'input', type: 'text', name: 'voter_registration', label: 'Título de eleitor' },
    born_in: { model: 'input', type: 'text', name: 'born_in', label: 'Naturalidade' },
    instituicao: {
      model: 'input',
      type: 'text',
      name: 'instituicao',
      label: 'Instituição',
    },


    type: { model: 'input', type: 'text', name: 'type', label: 'Categoria de Avaliação' },
    category_id: { model: 'input', type: 'text', name: 'category_id', label: 'Área de Avaliação' },
    subarea: { model: 'input', type: 'text', name: 'subarea', label: 'Subárea' },

    profile: {
      model: 'selectSimple',
      name: 'profile',
      label: 'Nível',
      defaultValue: '2',
      options: [
        { label: 'Administrador', value: 1 },
        { label: 'Participante', value: 2 },
        { label: 'Mediador / Palestrante', value: 3 },
        { label: 'Organização', value: 4 },
        { label: 'Expositor', value: 5 },
        { label: 'Moderador de Chat', value: 6 },

        { label: 'Avaliador de Currículo', value: 12 },
        { label: 'Equipe de apoio', value: 13 },
      ],
    },
  };

  if (user?.email === 'guilherme@encontrodigital.com.br') {
    formSchema.password_hash = {
      model: 'input',
      type: 'password',
      name: 'password_hash',
      label: 'Senha',
    }
  }

  const formValidation: Record<string, ValidationSchema> = {

  };


  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: user?.email === 'guilherme@encontrodigital.com.br' ? [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchema,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        contactModule({
          lineData

        }),
      (lineData: Record<string, any>) => sendPasswordAvaliator({ lineData }),
      (lineData: Record<string, any>) => sendPasswordUser({ lineData })
    ] :
      [(lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchema,
          validationSchema: {},
          endpoint,
        }),

      (lineData: Record<string, any>) =>
        contactModule({
          lineData

        }),
      (lineData: Record<string, any>) => sendPasswordAvaliator({ lineData }),
      (lineData: Record<string, any>) => sendPasswordUser({ lineData })

      ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default Dashboard;
