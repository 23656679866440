import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../../../hooks/Configuration';
import { mergeDateText } from '../../../../utils/date';
import { FaCalendarDay, FaCertificate, FaEye, FaIdBadge, FaImages, FaMap, FaPhotoVideo } from 'react-icons/fa';
import points, { oneHundredNumbers } from './utils/points';
import { useModal } from '../../../../hooks/Modal';
import ShowCurriculumContent from '../../features/ShowCurriculumContent';


import exportCurriculum from './Modules/ExportCurriculumValue';
import { SingleSaleReportModule } from './Modules/SingleSaleUserReport';
import { useToast } from '../../../../hooks/Toast';
import downloadit from '../../../../utils/download';
import template from './template/BlockRender';
import { useAuth } from '../../../../hooks/Auth';
import { CreateNewProjectModule } from './Modules/CreateNewProject';
import { FinanceReportByProjectModule } from './Modules/CheckFinance';

import isentionList from './Modules/ExportListIsention';
interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}


const options = [
  { label: '01', value: 1 },
  { label: '02', value: 2 },
  { label: '03', value: 3 },
  { label: '04', value: 4 },
  { label: '05', value: 5 },
  { label: '06', value: 6 },
  { label: '07', value: 7 },
  { label: '08', value: 8 },
  { label: '09', value: 9 },
  { label: '10', value: 10 },
  { label: '11', value: 11 },
  { label: '12', value: 12 },
  { label: '13', value: 13 },
  { label: '14', value: 14 },
  { label: '15', value: 15 },
  { label: '16', value: 16 },
  { label: '17', value: 17 },
  { label: '18', value: 18 },
  { label: '19', value: 19 },
  { label: '20', value: 20 },
  { label: '21', value: 21 },
  { label: '22', value: 22 },
  { label: '23', value: 23 },
  { label: '24', value: 24 },
  { label: '25', value: 25 },
  { label: '26', value: 26 },
  { label: '27', value: 27 },
  { label: '28', value: 28 },
  { label: '29', value: 29 },
  { label: '30', value: 30 },
  { label: '31', value: 31 },
  { label: '32', value: 32 },
  { label: '33', value: 33 },
  { label: '34', value: 34 },
  { label: '35', value: 35 },
  { label: '36', value: 36 },
  { label: '37', value: 37 },
  { label: '38', value: 38 },
  { label: '39', value: 39 },
  { label: '40', value: 40 },
  { label: '41', value: 41 },
  { label: '42', value: 42 },
  { label: '43', value: 43 },
  { label: '44', value: 44 },
  { label: '45', value: 45 },
  { label: '46', value: 46 },
  { label: '47', value: 47 },
  { label: '48', value: 48 },
  { label: '49', value: 49 },
  { label: '50', value: 50 },
  { label: '51', value: 51 },
  { label: '52', value: 52 },
  { label: '53', value: 53 },
  { label: '54', value: 54 },
  { label: '55', value: 55 },
  { label: '56', value: 56 },
  { label: '57', value: 57 },
  { label: '58', value: 58 },
  { label: '59', value: 59 },
  { label: '60', value: 60 },
  { label: '61', value: 61 },
  { label: '62', value: 62 },
  { label: '63', value: 63 },
  { label: '64', value: 64 },
  { label: '65', value: 65 },
  { label: '66', value: 66 },
  { label: '67', value: 67 },
  { label: '68', value: 68 },
  { label: '69', value: 69 },
  { label: '70', value: 70 },
  { label: '71', value: 71 },
  { label: '72', value: 72 },
  { label: '73', value: 73 },
  { label: '74', value: 74 },
  { label: '75', value: 75 },
  { label: '76', value: 76 },
  { label: '77', value: 77 },
  { label: '78', value: 78 },
  { label: '79', value: 79 },
  { label: '80', value: 80 },
  { label: '81', value: 81 },
  { label: '82', value: 82 },
  { label: '83', value: 83 },
  { label: '84', value: 84 },
  { label: '85', value: 85 },
  { label: '86', value: 86 },
  { label: '87', value: 87 },
  { label: '88', value: 88 },
  { label: '89', value: 89 },
  { label: '90', value: 90 },
  { label: '91', value: 91 },
  { label: '92', value: 92 },
  { label: '93', value: 93 },
  { label: '94', value: 94 },
  { label: '95', value: 95 },
  { label: '96', value: 96 },
  { label: '97', value: 97 },
  { label: '98', value: 98 },
  { label: '99', value: 99 },
  { label: '100', value: 100 },
]


const EventsAdmin: React.FC = () => {
  const endpoint = '/projects';
  const title = 'Projetos';
  const { user } = useAuth();
  const { projectHash, eventHash } = useConfiguration();
  const { addModal } = useModal();
  const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },
    hash: { ref: 'hash', column: 'hash', label: 'Hash', show: false },
    reference_number: { ref: 'reference_number', column: 'reference_number', label: 'Ref.' },
    url: { ref: 'url', column: 'url', label: 'URL' },
    title: { ref: 'title', column: 'title', label: 'Nome do evento' },
    color: { ref: 'color', column: 'color', label: 'Cor do Projeto', show: false },
    description: { ref: 'description', column: 'description', label: 'Descritivo', show: false },
    image: { ref: 'image', column: 'image', label: 'Capa de Seleção', show: false },
    stage: { ref: 'stage', column: 'stage', label: 'Etapa' },
    data_image: { ref: 'data_image', column: 'data_image', label: 'Imagem da Dasa', show: false },
    logo: { ref: 'logo', column: 'logo', label: 'Logo', show: false },
    background: { ref: 'background', column: 'background', label: 'Fundo', show: false },
    background_color: { ref: 'background_color', column: 'background_color', label: 'Cor de Fundo', show: false },

    next_date: { ref: 'next_date', column: 'next_date', label: 'Próxima Data', show: false },
    upper_text: { ref: 'upper_text', column: 'upper_text', label: 'Texto superior da Capa', show: false },
    header_image: { ref: 'header_image', column: 'header_image', label: 'Imagem de capa', show: false },
    lower_text: { ref: 'lower_text', column: 'lower_text', label: 'Texto inferior da Capa', show: false },
    header_video: { ref: 'header_video', column: 'header_video', label: 'Vídeo', show: false },
    fixed_image_header: { ref: 'fixed_image_header', column: 'fixed_image_header', label: 'Imagem de fundo Fixa', show: false },
    current_event_id: {
      ref: 'current_event_id',
      column: 'current_event_id_response',
      includeColumn: 'title',
      label: 'Evento atual',
      filter: true,
    },
    curriculum_configuration: { ref: 'curriculum_configuration', column: 'curriculum_configuration', label: 'Análise Currícular', show: false },

    status_id: {
      ref: 'status_id',
      column: 'status_id_response',
      includeColumn: 'title',
      label: 'Status',
      filter: true, show: false
    },
    createdAt: {
      ref: 'createdAt',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {

    reference_number: { model: 'input', type: 'text', name: 'reference_number', label: 'Número de referência' },
    title: { model: 'input', type: 'text', name: 'title', label: 'Nome do evento' },
    //  color: { model: 'input', type: 'text', name: 'color', label: 'Cor do projeto' },
    url: { model: 'input', type: 'text', name: 'url', label: 'URL' },
    description: { model: 'richText', type: 'text', name: 'description', label: 'descritivo' },
    bank_account: { model: 'input', type: 'text', name: 'bank_account', label: 'Conta Bancária' },
    stage: {
      label: 'Etapa', name: 'stage', model: 'selectSimple', default: 'subscription', options: [
        { text: 'Inscrição', label: 'Inscrição', value: 'subscription' },
        { text: 'Aguardando resultado da prova', label: 'Aguardando resultado da prova', value: 'exam' },
        { text: 'Envio de currículo', label: 'Envio de currículo', value: 'curriculum' },
        { text: 'Avaliação de currículo', label: 'Avaliação de currículo', value: 'curriculum_waiting' },
        { text: 'Pré-matricula', label: 'Pré-matricula', value: 'registration' },
        { text: 'Confirmação de matrícula', label: 'Confirmação de matrícula', value: 'confirmation' },
      ]
    },



    //    program_title: { model: 'input', type: 'text', name: 'program_title', label: 'Título da Programação' },
    //    program_subtitle: { model: 'input', type: 'text', name: 'program_subtitle', label: 'Subtítulo da Programação' },
    //   upper_text: { model: 'input', type: 'text', name: 'upper_text', label: 'Texto superior da Capa' },

    //   lower_text: { model: 'input', type: 'text', name: 'lower_text', label: 'Texto inferior da Capa' },
    //  header_video: { model: 'input', type: 'text', name: 'header_video', label: 'Link do Vídeo da Capa' },

    //   next_date: { model: 'datePickerHour', type: 'text', name: 'next_date', label: 'Próxima data' },

    //  background_color: { model: 'input', type: 'text', name: 'background_color', label: 'Cor de Fundo' },
    current_event_id: {
      alias: 'current_event_id',
      model: 'selectApi',
      name: 'current_event_id',
      label: 'Evento atual',
      endpoint,
    },
    /*   status_id: {
       alias: 'status_id',
       model: 'selectApi',
       name: 'status_id',
       label: 'status',
       endpoint,
     },
 
     theme_data: {
       model: 'jsonList',
       type: 'text',
       name: 'theme_data',
       label: 'Temática',
       list: [
         { label: 'Fundo Primário', name: 'primary_background' },
         { label: 'Cor Primária', name: 'primary_color' },
         { label: 'Fundo secundária', name: 'secondary_background' },
         { label: 'Cor secundária', name: 'secondary_color' },
         {
           label: 'Página - Modelo', name: 'page-theme', type: 'select', options: [
 
             { label: 'Padrão', value: 'default' },
             { label: 'Single Page', value: 'single_page' },
             { label: 'Sidebar', value: 'sidebar' },
 
           ]
         },
         {
           label: 'Programação Modelo', name: 'program-theme', type: 'select', options: [
 
             { label: 'Padrão', value: 'default' },
             { label: 'Clean', value: 'clean' },
 
           ]
         },
         {
           label: 'Mostrar Cabeçalho da Programação?', name: 'program_header', type: 'select', default: 'yes', options: [
 
             { label: 'Sim', value: 'yes' },
             { label: 'Não', value: 'no' },
 
           ]
         },
         { label: 'Cor secundária', name: 'secondary_color' },
         { label: 'Cor secundária', name: 'secondary_color' },
 
       ],
     },
 
  
 */
    active_pages: {
      model: 'jsonListMultiple',
      type: 'text',
      name: 'active_pages',
      label: 'Páginas / Menu',
      list: {
        hash: { label: 'Hash', name: 'hash', type: 'input' },
        title: { label: 'Título', name: 'title', type: 'input' },
        url: { label: 'Link', name: 'url', type: 'input' },
        menu: { label: 'Menu?', name: 'menu', type: 'select', options: [{ text: 'Sim', value: 'yes' }, { text: 'Não', value: 'no' }] },
        button: { label: 'Botão', name: 'button', type: 'select', options: [{ text: 'Sim', value: 'yes' }, { text: 'Não', value: 'no' }] },
        active: { label: 'Ativo?', name: 'active', type: 'select', options: [{ text: 'Sim', value: 'yes' }, { text: 'Não', value: 'no' }] },
        signed: { label: 'Logado?', name: 'signed', type: 'select', options: [{ text: 'Sim', value: 'yes' }, { text: 'Não', value: 'no' }] },
        type: { label: 'Tipo', name: 'type', type: 'select', options: [{ text: 'Página principal', value: 'page' }, { text: 'Aba separada', value: 'single_page' }, { text: 'Modal', value: 'modal' }, { text: 'Link externo', value: 'external' }, { text: 'Menu', value: 'menu' }] },
      },
      defaultValue:
        [
          { hash: 'home', url: '', title: 'Home', menu: 'no', button: 'no', active: 'no', signed: 'no', type: 'page' },
          { hash: 'product', url: 'inscricao', title: 'Venda', menu: 'no', button: 'no', active: 'no', signed: 'no', type: 'page' },
          { hash: 'signup', url: 'inscricao', title: 'Inscrição', menu: 'no', button: 'no', active: 'no', signed: 'no', type: 'page' },
          { hash: 'program', url: 'programacao', title: 'Programação', menu: 'no', button: 'no', active: 'no', signed: 'no', type: 'page' },
          { hash: 'speakers', url: 'palestrantes', title: 'Palestrantes', menu: 'no', button: 'no', active: 'no', signed: 'no', type: 'page' },
          { hash: 'poster', url: 'poster', title: 'Pôster', menu: 'no', button: 'no', active: 'no', signed: 'no', type: 'page' },
          { hash: 'certificate', url: 'certificados', title: 'Certificados', menu: 'no', button: 'no', active: 'no', signed: 'no', type: 'page' },
          { hash: 'files', url: 'arquivos-de-apoio', title: 'Arquivos de Apoio', menu: 'no', active: 'no', signed: 'no', type: 'page' },
          { hash: 'faq', url: 'faq', title: 'FAQ', menu: 'no', active: 'no', signed: 'no', type: 'page' },
          { hash: 'isention', url: 'solicitar-isencao', title: 'Solicitar isenção do pagamento do valor da inscrição', menu: 'no', active: 'no', signed: 'no', type: 'menu' },
        ]
    },
    finance_status: {
      model: 'selectSimple',
      name: 'finance_status',
      label: 'Envio de relatório financeiro ativo?',



      options: [


        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },
    allow_isention_recurse: {
      model: 'selectSimple',
      name: 'allow_isention_recurse',
      label: 'Autorizar envio de solicitação de isenção?',



      options: [


        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },
    allow_isention_recurse_against: {
      model: 'selectSimple',
      name: 'allow_isention_recurse_against',
      label: 'Autorizar envio de recurso de isenção?',



      options: [


        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },
    allow_curriculum_avaliation: {
      model: 'selectSimple',
      name: 'allow_curriculum_avaliation',
      label: 'Autorizar avaliação currícular',



      options: [


        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },
    allow_curriculum_avaliation_show: {
      model: 'selectSimple',
      name: 'allow_curriculum_avaliation_show',
      label: 'Autorizar visualização das avaliações pelos participantes',



      options: [


        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },
    allow_curriculum_recurse: {
      model: 'selectSimple',
      name: 'allow_curriculum_recurse',
      label: 'Autorizar etapa de recursos',



      options: [


        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },
    allow_curriculum_recurse_show: {
      model: 'selectSimple',
      name: 'allow_curriculum_recurse_show',
      label: 'Mostrar retorno da etapa de recursos',



      options: [


        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },


    allow_cancel: {
      model: 'selectSimple',
      name: 'allow_cancel',
      label: 'Permitir Cancelamento',



      options: [


        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },
    allow_reserve: {
      model: 'selectSimple',
      name: 'allow_reserve',
      label: 'Permitir Reserva Militar',



      options: [


        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },
    allow_special_treatment: {
      model: 'selectSimple',
      name: 'allow_special_treatment',
      label: 'Permitir Atendimento especial',



      options: [


        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },
    allow_subscription_recurse: {
      model: 'selectSimple',
      name: 'allow_subscription_recurse',
      label: 'Permitir Recurso de inscrição',



      options: [


        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },
        { label: 'Somente visualização', value: 'open' },

      ]

    },
    allow_proof_recurse: {
      model: 'selectSimple',
      name: 'allow_proof_recurse',
      label: 'Permitir Recurso de Prova',
      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },
        { label: 'Somente visualização', value: 'open' },
      ]
    },
    allow_boleto: {
      model: 'selectSimple',
      name: 'allow_boleto',
      label: 'Liberar Boleto',



      options: [


        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },


    proof_recurses_questions: {

      name: 'proof_recurses_questions',
      label: 'Recurso de prova - Acesso direto',


      model: 'jsonInputList',
      type: 'text',


      show: ['category', 'quantity'],
      list: [
        { label: 'Categoria', name: 'category', type: 'input' },
        { label: 'Quantidade de questões', name: 'quantity', type: 'input' },


      ]

    },


    proof_recurses_questions_pre_requisitos: {

      name: 'proof_recurses_questions_pre_requisitos',
      label: 'Recurso de prova - Pré-requisitos ',


      model: 'jsonInputList',
      type: 'text',


      show: ['category', 'quantity'],
      list: [
        { label: 'Categoria', name: 'category', type: 'input' },
        { label: 'Quantidade de questões', name: 'quantity', type: 'input' },


      ]
    },



    /* 
        proof_books_pre_requisitos: {
          model: 'selectSimple',
          name: 'proof_books_pre_requisitos',
          label: 'Recurso de prova - Pré-requisitos - Número de Cadernos',
    
    
    
          options: oneHundredNumbers()
    
        },
    
    
        proof_questions_pre_requisitos: {
          model: 'selectSimple',
          name: 'proof_questions_pre_requisitos',
          label: 'Recurso de prova - Pré-requisitos - Número de Questões',
    
    
    
          options: oneHundredNumbers()
    
        },
    
    
    
        proof_questions: {
          model: 'selectSimple',
          name: 'proof_questions',
          label: 'Recurso de prova - Acesso direto - Número de Questões',
    
    
    
          options: oneHundredNumbers()
    
        },
      */


    allow_cortesy: {
      model: 'selectSimple',
      name: 'allow_cortesy',
      label: 'Liberar Cortesia',



      options: [


        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },
    allow_credit_card: {
      model: 'selectSimple',
      name: 'allow_credit_card',
      label: 'Liberar Cartão de Credito',



      options: [


        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },
    allow_pix: {
      model: 'selectSimple',
      name: 'allow_pix',
      label: 'Liberar PIX',



      options: [


        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },

    create_subscription: {
      model: 'selectSimple',
      name: 'create_subscription',
      label: 'Criação automática de matrícula',



      options: [


        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },
    new_finance: {
      model: 'selectSimple',
      name: 'new_finance',
      label: 'Novo modelo bancário?',



      options: [


        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },
    withdraw: {
      model: 'selectSimple',
      name: 'withdraw',
      label: 'Saque ativo?',



      options: [


        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },
  };



  const formSchemaLocalAddress = {

    local_address: { model: 'richText', type: 'text', name: 'local_address', label: 'Endereço da prova' },
    local_map: { model: 'input', type: 'text', name: 'local_map', label: 'Embed do google maps' },

  };

  const formSchemaCurriculum = {
    curriculum_configuration: {
      model: 'jsonInputList',
      type: 'text',
      name: 'curriculum_configuration',
      label: 'Categorias de Currículo - ACESSO DIRETO',
      show: 'title',
      list: [
        { label: 'Hash', name: 'hash', type: 'input' },
        { label: 'Título', name: 'title', type: 'input' },
        { label: 'Descritivo', name: 'description', type: 'richText' },
        { label: 'Nota Máxima', name: 'points', type: 'select', options: points },
        {
          label: 'Mostrar para o participante?', name: 'status', default: 'yes', type: 'select', options: [
            { text: 'Sim', label: 'Sim', value: 'yes' },
            { text: 'Não', label: 'Não', value: 'no' },
          ]
        },
        {
          label: 'Segmentar por categoria?', name: 'show_subcategories', default: 'no', type: 'select', options: [
            { text: 'Sim', label: 'Sim', value: 'yes' },
            { text: 'Não', label: 'Não', value: 'no' },
          ]
        },
        {
          label: 'Pontuação Livre?', name: 'allow_free_points', default: 'no', type: 'select', options: [
            { text: 'Sim', label: 'Sim', value: 'yes' },
            { text: 'Não', label: 'Não', value: 'no' },
          ]
        },
        {
          label: 'Categorias', name: 'categories', type: 'jsonList', list: [
            { label: 'Hash', name: 'hash', type: 'input' },
            { label: 'Título', name: 'title', type: 'input' },
            { label: 'Pontuação', name: 'value', type: 'select', options: points },
            { label: 'Nota máxima', name: 'points', type: 'select', options: points },
            {
              label: 'Subcategorias', name: 'subcategories', type: 'example', list: [
                { label: 'Hash', name: 'hash', type: 'input' },
                { label: 'Título', name: 'title', type: 'input' },
                { label: 'Pontuação', name: 'value', type: 'select', options: points },

              ]
            },
          ]
        },
      ]
    },
    curriculum_configuration_pre_requisitos: {
      model: 'jsonInputList',
      type: 'text',
      name: 'curriculum_configuration_pre_requisitos',
      label: 'Categorias de Currículo - Pré-requisitos',
      show: 'title',
      list: [
        { label: 'Hash', name: 'hash', type: 'input' },
        { label: 'Título', name: 'title', type: 'input' },
        { label: 'Descritivo', name: 'description', type: 'richText' },
        { label: 'Nota Máxima', name: 'points', type: 'select', options: points },
        {
          label: 'Mostrar para o participante?', name: 'status', default: 'yes', type: 'select', options: [
            { text: 'Sim', label: 'Sim', value: 'yes' },
            { text: 'Não', label: 'Não', value: 'no' },
          ]
        },
        {
          label: 'Segmentar por categoria?', name: 'show_subcategories', default: 'no', type: 'select', options: [
            { text: 'Sim', label: 'Sim', value: 'yes' },
            { text: 'Não', label: 'Não', value: 'no' },
          ]
        },
        {
          label: 'Pontuação Livre?', name: 'allow_free_points', default: 'no', type: 'select', options: [
            { text: 'Sim', label: 'Sim', value: 'yes' },
            { text: 'Não', label: 'Não', value: 'no' },
          ]
        },
        {
          label: 'Categorias', name: 'categories', type: 'jsonList', list: [
            { label: 'Hash', name: 'hash', type: 'input' },
            { label: 'Título', name: 'title', type: 'input' },
            { label: 'Pontuação', name: 'value', type: 'select', options: points },
            { label: 'Nota máxima', name: 'points', type: 'select', options: points },
            {
              label: 'Subcategorias', name: 'subcategories', type: 'example', list: [
                { label: 'Hash', name: 'hash', type: 'input' },
                { label: 'Título', name: 'title', type: 'input' },
                { label: 'Pontuação', name: 'value', type: 'select', options: points },

              ]
            },
          ]
        },
      ],

    },
    curriculum_configuration_especial: {
      model: 'jsonInputList',
      type: 'text',
      name: 'curriculum_configuration_especial',
      label: 'Categorias de Currículo - ESPECIAL',
      show: 'title',
      list: [
        { label: 'Hash', name: 'hash', type: 'input' },
        { label: 'Título', name: 'title', type: 'input' },
        { label: 'Descritivo', name: 'description', type: 'richText' },
        { label: 'Nota Máxima', name: 'points', type: 'select', options: points },
        {
          label: 'Mostrar para o participante?', name: 'status', default: 'yes', type: 'select', options: [
            { text: 'Sim', label: 'Sim', value: 'yes' },
            { text: 'Não', label: 'Não', value: 'no' },
          ]
        },
        {
          label: 'Segmentar por categoria?', name: 'show_subcategories', default: 'no', type: 'select', options: [
            { text: 'Sim', label: 'Sim', value: 'yes' },
            { text: 'Não', label: 'Não', value: 'no' },
          ]
        },
        {
          label: 'Pontuação Livre?', name: 'allow_free_points', default: 'no', type: 'select', options: [
            { text: 'Sim', label: 'Sim', value: 'yes' },
            { text: 'Não', label: 'Não', value: 'no' },
          ]
        },
        {
          label: 'Categorias', name: 'categories', type: 'jsonList', list: [
            { label: 'Hash', name: 'hash', type: 'input' },
            { label: 'Título', name: 'title', type: 'input' },
            { label: 'Pontuação', name: 'value', type: 'select', options: points },
            { label: 'Nota máxima', name: 'points', type: 'select', options: points },
            {
              label: 'Subcategorias', name: 'subcategories', type: 'example', list: [
                { label: 'Hash', name: 'hash', type: 'input' },
                { label: 'Título', name: 'title', type: 'input' },
                { label: 'Pontuação', name: 'value', type: 'select', options: points },

              ]
            },
          ]
        },
      ]
    },
    aditional_curriculum_percent: {
      model: 'input',
      name: 'aditional_curriculum_percent',
      label: 'Multiplicador de Bônus de Currículo (Ex: 0.1)',
    },
    max_curriculum_value: {
      model: 'input',
      name: 'max_curriculum_value',
      label: 'Pontuação máxima da etapa de currículo',
    },
  }


  const formSchemaMatricula = {

    documents_configuration: {
      model: 'jsonInputList',
      type: 'text',
      name: 'documents_configuration',
      label: 'Documentos necessários',
      show: 'title',
      list: [
        { label: 'Hash', name: 'hash', type: 'input' },
        { label: 'Título', name: 'title', type: 'input' },
        { label: 'Descritivo', name: 'description', type: 'richText' },
        { label: 'Referência', name: 'reference', type: 'input' },
        {
          label: 'Obrigatório?', name: 'mandatory', default: 'yes', type: 'select', options: [
            { text: 'Sim', label: 'Sim', value: 'yes' },
            { text: 'Não', label: 'Não', value: 'no' },
          ]
        },
        {
          label: 'Mostrar para o participante?', name: 'status', default: 'yes', type: 'select', options: [
            { text: 'Sim', label: 'Sim', value: 'yes' },
            { text: 'Não', label: 'Não', value: 'no' },
          ]
        },
        {
          label: 'Categorias', name: 'categories', type: 'jsonList', list: [
            { label: 'Hash', name: 'hash', type: 'input' },
            { label: 'Título', name: 'title', type: 'input' },
            { label: 'Pontuação', name: 'value', type: 'select', options: points },
            { label: 'Nota máxima', name: 'points', type: 'select', options: points },
            {
              label: 'Subcategorias', name: 'subcategories', type: 'example', list: [
                { label: 'Hash', name: 'hash', type: 'input' },
                { label: 'Título', name: 'title', type: 'input' },
                { label: 'Pontuação', name: 'value', type: 'select', options: points },

              ]
            },
          ]
        },
      ],
      defaultValue: [

        {
          hash: 'rg',
          title: 'Documento de identidade civil (RG)',
          description: 'comprovando ser brasileiro nato ou naturalizado, ou estar gozando das prerrogativas constantes do artigo 12 da Constituição Federal (preferencialmente aquele utilizado para inscrição)',
          mandatory: 'yes',
          status: 'yes'
        },
        {
          hash: 'cpf',
          title: 'CPF e Comprovante de Situação cadastral do CPF',
          description: '',
          mandatory: 'yes',
          status: 'yes'
        },
        {
          hash: 'titulo-eleitor',
          title: 'Título de Eleitor e Certidão de Quitação Eleitoral, comprovando o gozo dos direitos civis e políticos',
          description: '',
          mandatory: 'yes',
          status: 'yes'
        },
        {
          hash: 'documento_militar',
          title: 'Documentação Militar - Certificado de Reservista (somente para homens)',
          description: '',
          mandatory: 'no',
          status: 'yes'
        },
        {
          hash: 'pis',
          title: 'Inscrição no cadastro de participante do PIS/PASEP ou NIS (CNIS - INSS), ou CTPS em que conste o número do PIS/PASEP',
          description: '',
          mandatory: 'yes',
          status: 'yes'
        },
        {
          hash: 'comprovante-residencia',
          title: 'Comprovante de Residência e Comprovante de CEP',
          description: '',
          mandatory: 'yes',
          status: 'yes'
        },



        {
          hash: 'diploma',
          title: 'Documento comprobatório de conclusão do curso superior (certificado ou diploma) na categoria profissional de inscrição ou declaração de conclusão do curso',
          description: '',
          mandatory: 'yes',
          status: 'yes'
        },


        {
          hash: 'carteira_profissional',
          title: 'Carteira de Registro Profissional comprovando inscrição no Conselho de Classe Profissional do Rio Grande do Sul ou Declaração de Inscrição em que conste que o aluno está apto ao exercício regular da profissão no Rio Grande do Sul',
          description: '',
          mandatory: 'no',
          status: 'yes'
        },
        {
          hash: 'certidao-conselho',
          title: 'Certidão de regularidade com o Conselho de Classe Profissional do Rio Grande do Sul',
          description: '(válido por, no mínimo, 90 dias) ou declaração correspondente ',
          mandatory: 'no',
          status: 'yes'
        },
        {
          hash: 'conta-bancaria',
          title: 'Comprovante de conta corrente pessoa física em nome do candidato',
          description: '',
          mandatory: 'yes',
          status: 'yes'
        },
        {
          hash: 'auto-declaracao-vacinas',
          title: 'Anexar cópia da carteira de vacinação',
          description: '',
          mandatory: 'yes',
          status: 'yes'
        },
        {
          hash: 'tipo_sanguinio',
          title: 'Comprovante do tipo sanguíneo emitido por laboratório ou instituição de saúde, ou documento oficial em que conste o tipo sanguíneo.',
          description: '',
          mandatory: 'yes',
          status: 'yes'
        },
        {
          hash: 'curriculo',
          title: 'Currículo (conforme ANEXO IV - INSTRUÇÕES E MODELO CURRICULUM VITAE)',
          description: '',
          mandatory: 'yes',
          status: 'yes'
        },




      ]
    },

    documents_configuration_pre_requisitos: {
      model: 'jsonInputList',
      type: 'text',
      name: 'documents_configuration_pre_requisitos',
      label: 'Documentos necessários para pré-requisitos',
      show: 'title',
      list: [
        { label: 'Hash', name: 'hash', type: 'input' },
        { label: 'Título', name: 'title', type: 'input' },
        { label: 'Referência', name: 'reference', type: 'input' },
        { label: 'Descritivo', name: 'description', type: 'richText' },
        {
          label: 'Obrigatório?', name: 'mandatory', default: 'yes', type: 'select', options: [
            { text: 'Sim', label: 'Sim', value: 'yes' },
            { text: 'Não', label: 'Não', value: 'no' },
          ]
        },
        {
          label: 'Mostrar para o participante?', name: 'status', default: 'yes', type: 'select', options: [
            { text: 'Sim', label: 'Sim', value: 'yes' },
            { text: 'Não', label: 'Não', value: 'no' },
          ]
        },
        {
          label: 'Categorias', name: 'categories', type: 'jsonList', list: [
            { label: 'Hash', name: 'hash', type: 'input' },
            { label: 'Título', name: 'title', type: 'input' },
            { label: 'Pontuação', name: 'value', type: 'select', options: points },
            { label: 'Nota máxima', name: 'points', type: 'select', options: points },
            {
              label: 'Subcategorias', name: 'subcategories', type: 'example', list: [
                { label: 'Hash', name: 'hash', type: 'input' },
                { label: 'Título', name: 'title', type: 'input' },
                { label: 'Pontuação', name: 'value', type: 'select', options: points },

              ]
            },
          ]
        },
      ],
      defaultValue: [

        {
          hash: 'rg',
          title: 'Documento de identidade civil (RG)',
          description: 'comprovando ser brasileiro nato ou naturalizado, ou estar gozando das prerrogativas constantes do artigo 12 da Constituição Federal (preferencialmente aquele utilizado para inscrição)',
          mandatory: 'yes',
          status: 'yes'
        },
        {
          hash: 'cpf',
          title: 'CPF e Comprovante de Situação cadastral do CPF',
          description: '',
          mandatory: 'yes',
          status: 'yes'
        },
        {
          hash: 'titulo-eleitor',
          title: 'Título de Eleitor e Certidão de Quitação Eleitoral, comprovando o gozo dos direitos civis e políticos',
          description: '',
          mandatory: 'yes',
          status: 'yes'
        },
        {
          hash: 'documento_militar',
          title: 'Documentação Militar - Certificado de Reservista (somente para homens)',
          description: '',
          mandatory: 'no',
          status: 'yes'
        },
        {
          hash: 'pis',
          title: 'Inscrição no cadastro de participante do PIS/PASEP ou NIS (CNIS - INSS), ou CTPS em que conste o número do PIS/PASEP',
          description: '',
          mandatory: 'yes',
          status: 'yes'
        },
        {
          hash: 'comprovante-residencia',
          title: 'Comprovante de Residência e Comprovante de CEP',
          description: '',
          mandatory: 'yes',
          status: 'yes'
        },


        {
          hash: 'diploma-pre-requisitos',
          title: 'Documento comprobatório de conclusão do pré-requisito exigido para o programa de inscrição, de acordo com a legislação vigente',
          description: '',
          mandatory: 'yes',
          status: 'yes'
        },

        {
          hash: 'diploma',
          title: 'Documento comprobatório de conclusão do curso superior (certificado ou diploma) na categoria profissional de inscrição ou declaração de conclusão do curso',
          description: '',
          mandatory: 'yes',
          status: 'yes'
        },


        {
          hash: 'carteira_profissional',
          title: 'Carteira de Registro Profissional comprovando inscrição no Conselho de Classe Profissional do Rio Grande do Sul ou Declaração de Inscrição em que conste que o aluno está apto ao exercício regular da profissão no Rio Grande do Sul',
          description: '',
          mandatory: 'no',
          status: 'yes'
        },
        {
          hash: 'certidao-conselho',
          title: 'Certidão de regularidade com o Conselho de Classe Profissional do Rio Grande do Sul',
          description: '(válido por, no mínimo, 90 dias) ou declaração correspondente ',
          mandatory: 'no',
          status: 'yes'
        },
        {
          hash: 'conta-bancaria',
          title: 'Comprovante de conta corrente pessoa física em nome do candidato',
          description: '',
          mandatory: 'yes',
          status: 'yes'
        },
        {
          hash: 'auto-declaracao-vacinas',
          title: 'Anexar cópia da carteira de vacinação',
          description: '',
          mandatory: 'yes',
          status: 'yes'
        },
        {
          hash: 'tipo_sanguinio',
          title: 'Comprovante do tipo sanguíneo emitido por laboratório ou instituição de saúde, ou documento oficial em que conste o tipo sanguíneo.',
          description: '',
          mandatory: 'yes',
          status: 'yes'
        },
        {
          hash: 'curriculo',
          title: 'Currículo (conforme ANEXO IV - INSTRUÇÕES E MODELO CURRICULUM VITAE)',
          description: '',
          mandatory: 'yes',
          status: 'yes'
        },




      ]
    },


    subscription_configuration: {
      model: 'jsonInputList',
      type: 'text',
      name: 'subscription_configuration',
      label: 'Dados pessoais necessários',
      show: 'title',
      list: [
        { label: 'Hash', name: 'hash', type: 'input' },
        { label: 'Título', name: 'title', type: 'input' },
        { label: 'Descritivo', name: 'description', type: 'richText' },
        {
          label: 'Obrigatório?', name: 'mandatory', default: 'yes', type: 'select', options: [
            { text: 'Sim', label: 'Sim', value: 'yes' },
            { text: 'Não', label: 'Não', value: 'no' },
          ]
        },
        {
          label: 'Tipo', name: 'type', default: 'yes', type: 'select', options: [
            { text: 'Texto comum', label: 'Texto comum', value: 'input' },
            { text: 'Texto com máscara', label: 'Texto com máscara', value: 'maskedInput' },

            { text: 'Seleção', label: 'Seleção', value: 'selectSimple' },

            { text: 'Lista de Bancos', label: 'Lista de Bancos', value: 'bankList' },
            { text: 'Lista de Orgãos expedidores de RG', label: 'Lista de Orgãos expedidores de RG', value: 'orgaoRg' },
            { text: 'Lista de Estados', label: 'Lista de Estados', value: 'selectState' },

          ]
        },
        {
          label: 'Subtipo', name: 'subtype', default: '', type: 'select', options: [
            { text: 'Nenhum', label: 'Nenhum', value: '' },
            { text: 'Somente Letras', label: 'Somente Letras', value: 'letter' },
            { text: 'Somente Números', label: 'Somente Números', value: 'number' },
          ]
        },
        { label: 'Máscara', name: 'mask', type: 'input' },
        {
          label: 'Opções de Seleção', name: 'options', type: 'jsonList', list: [

            { label: 'Título', name: 'label', type: 'input' },
            { label: 'Valor', name: 'value', type: 'input' },

          ]
        },

      ],

      defaultValue: [
        { hash: 'badge_name', title: 'NOME QUE DESEJA USAR NO CRACHÁ', description: '', mandatory: 'yes', type: 'input', subtype: 'letter', mask: '', options: [] },
        { hash: 'birth_date', title: 'DATA DE NASCIMENTO', description: '', mandatory: 'yes', type: 'maskedInput', subtype: '', mask: '99/99/9999', options: [] },
        { hash: 'bank_name', title: 'BANCO', description: '', mandatory: 'yes', type: 'bankList', subtype: '', mask: '', options: [] },
        { hash: 'bank_agency', title: 'NÚMERO DA AGÊNCIA', description: '', mandatory: 'yes', type: 'input', subtype: '', mask: '', options: [] },
        { hash: 'bank_agency_dv', title: 'DÍGITO VERIFICADOR DA AGÊNCIA', description: '', mandatory: 'yes', type: 'input', subtype: '', mask: '', options: [] },
        { hash: 'bank_account', title: 'NÚMERO DA CONTA CORRENTE', description: '', mandatory: 'yes', type: 'input', subtype: '', mask: '', options: [] },
        { hash: 'bank_account_dv', title: 'DÍGITO VERIFICADOR DA CONTA CORRENTE', description: '', mandatory: 'yes', type: 'input', subtype: '', mask: '', options: [] },
        {
          hash: 'marital_status', title: 'ESTADO CIVIL', description: '', mandatory: 'yes', type: 'selectSimple', subtype: '', mask: '', options: [
            { value: 'Solteiro(a)', label: 'Solteiro(a)' },
            { value: 'Casado(a)', label: 'Casado(a)' },
            { value: 'Separado(a)', label: 'Separado(a)' },
            { value: 'Divorciado(a)', label: 'Divorciado(a)' },
            { value: 'Viúvo(a)', label: 'Viúvo(a)' },
            { value: 'União estável', label: 'União estável' },
          ]
        },

        { hash: 'work_number', title: 'PIS/NIT/NIS', description: '', mandatory: 'yes', type: 'input', subtype: 'number', mask: '', options: [] },

        {
          hash: 'blood_type', title: 'TIPO SANGUÍNEO', description: '', mandatory: 'yes', type: 'selectSimple', subtype: '', mask: '', options: [
            { value: 'A', label: 'A' },
            { value: 'AB', label: 'AB' },
            { value: 'B', label: 'B' },
            { value: 'O', label: 'O' },
          ]
        },
        {
          hash: 'blood_type_factor', title: 'FATOR RH SANGUÍNEO', description: '', mandatory: 'yes', type: 'selectSimple', subtype: '', mask: '', options: [
            { value: 'POSITIVO', label: '(+) Positivo' },
            { value: 'NEGATIVO', label: '(-) Negativo' },
          ]
        },
        { hash: 'rg', title: 'CARTEIRA DE IDENTIDADE - RG', description: '', mandatory: 'yes', type: 'input', subtype: '', mask: '', options: [] },
        { hash: 'rg_date', title: 'DATA DE EXPEDIÇÃO DO RG', description: '', mandatory: 'yes', type: 'maskedInput', subtype: '', mask: '99/99/9999', options: [] },
        { hash: 'rg_emissor', title: 'ÓRGÃO EXPEDIDOR DO RG', description: '', mandatory: 'yes', type: 'orgaoRg', subtype: '', mask: '', options: [] },
        {
          hash: 'gender_id', title: 'GÊNERO', description: '', mandatory: 'yes', type: 'selectSimple', subtype: '', mask: '', options: [
            { value: 'Masculino', label: 'Masculino', text: 'Masculino' },
            { value: 'Feminino', label: 'Feminino', text: 'Feminino' }
          ]
        },
        { hash: 'born_in', title: 'NATURALIDADE', description: '', mandatory: 'yes', type: 'input', subtype: 'letter', mask: '', options: [] },
        { hash: 'nationality', title: 'NACIONALIDADE', description: '', mandatory: 'yes', type: 'input', subtype: 'letter', mask: '', options: [] },
        { hash: 'mother_name', title: 'NOME COMPLETO DA MÃE', description: '', mandatory: 'yes', type: 'input', subtype: 'letter', mask: '', options: [] },
        { hash: 'father_name', title: 'NOME COMPLETO DO PAI', description: '', mandatory: 'no', type: 'input', subtype: 'letter', mask: '', options: [] },
        { hash: 'zipcode', title: 'CEP', description: '', mandatory: 'yes', type: 'zipcode', subtype: '', mask: '', options: [] },
        { hash: 'address', title: 'ENDEREÇO', description: '', mandatory: 'yes', type: 'input', subtype: '', mask: '', options: [] },
        { hash: 'address_number', title: 'NÚMERO', description: '', mandatory: 'yes', type: 'input', subtype: '', mask: '', options: [] },
        { hash: 'address_number_extra', title: 'COMPLEMENTO', description: '', mandatory: 'no', type: 'input', subtype: '', mask: '', options: [] },
        { hash: 'neighborhood', title: 'BAIRRO', description: '', mandatory: 'yes', type: 'input', subtype: '', mask: '', options: [] },
        { hash: 'city', title: 'CIDADE', description: '', mandatory: 'yes', type: 'input', subtype: '', mask: '', options: [] },
        { hash: 'state', title: 'ESTADO', description: '', mandatory: 'yes', type: 'selectState', subtype: '', mask: '', options: [] },

        { hash: 'ddd_home', title: 'DDD DO TELEFONE RESIDENCIAL', description: '', mandatory: 'yes', type: 'maskedInput', subtype: '', mask: '99', options: [] },
        { hash: 'phone', title: 'TELEFONE RESIDENCIAL', description: '', mandatory: 'yes', type: 'maskedInput', subtype: '', mask: '999999999', options: [] },

        { hash: 'ddd_mobile', title: 'DDD DO CELULAR', description: '', mandatory: 'yes', type: 'maskedInput', subtype: '', mask: '99', options: [] },
        { hash: 'mobile', title: 'TELEFONE CELULAR', description: '', mandatory: 'yes', type: 'maskedInput', subtype: '', mask: '999999999', options: [] },

        {
          hash: 'graduation', title: 'GRAU DE INSTRUÇÃO', description: '', mandatory: 'yes', type: 'selectSimple', subtype: '', mask: '', options: [
            { value: "SUPERIOR COMPLETO", label: 'SUPERIOR COMPLETO', text: 'SUPERIOR COMPLETO' },
            { value: "MESTRADO INCOMPLETO", label: 'MESTRADO INCOMPLETO', text: 'MESTRADO INCOMPLETO' },
            { value: "MESTRADO", label: 'MESTRADO', text: 'MESTRADO' },
            { value: "DOUTORADO INCOMPLETO", label: 'DOUTORADO INCOMPLETO', text: 'DOUTORADO INCOMPLETO' },
            { value: "DOUTORADO", label: 'DOUTORADO', text: 'DOUTORADO' },
            { value: "PÓS-DOUTORADO", label: 'PÓS-DOUTORADO', text: 'PÓS-DOUTORADO' }
          ]
        },

        {
          hash: 'race', title: 'RAÇA/COR', description: '', mandatory: 'yes', type: 'selectSimple', subtype: '', mask: '', options: [
            { value: "INDÍGENA,", label: 'INDÍGENA,', text: 'INDÍGENA,' },
            { value: "BRANCA", label: 'BRANCA', text: 'BRANCA' },
            { value: "PRETA", label: 'PRETA', text: 'PRETA' },
            { value: "AMARELA", label: 'AMARELA', text: 'AMARELA' },
            { value: "PARDA", label: 'PARDA', text: 'PARDA' },
          ]
        },


        { hash: 'voter_registration', title: 'TÍTULO DE ELEITOR', description: '', mandatory: 'yes', type: 'maskedInput', subtype: '', mask: '999999999999', options: [] },
        { hash: 'votation_date', title: 'DATA DE EMISSÃO DO TITULO DE ELEITOR', description: '', mandatory: 'yes', type: 'maskedInput', subtype: '', mask: '99/99/9999', options: [] },
        { hash: 'votation_zone', title: 'ZONA DO TITULO DE ELEITOR', description: '', mandatory: 'yes', type: 'input', subtype: '', mask: '', options: [] },
        { hash: 'votation_section', title: 'SEÇÃO DO TITULO DE ELEITOR', description: '', mandatory: 'yes', type: 'input', subtype: '', mask: '', options: [] },
        { hash: 'reservation_number', title: 'NUMERO DO RA - CERTIFICADO DE RESERVISTA', description: '', mandatory: 'no', type: 'input', subtype: '', mask: '', options: [] },
        { hash: 'birth_uf', title: 'UF DE NASCIMENTO', description: '', mandatory: 'yes', type: 'selectState', subtype: '', mask: '', options: [] },

        { hash: 'profissional_number', title: 'NÚMERO DE REGISTRO PROFISSIONAL', description: '', mandatory: 'no', type: 'input', subtype: '', mask: '', options: [] },
        { hash: 'profissional_number_date', title: 'DATA DO REGISTRO PROFISSSIONAL', description: '', mandatory: 'no', type: 'maskedInput', subtype: '', mask: '99/99/9999', options: [] },

        { hash: 'instituicao', title: 'UNIVERSIDADE DE GRADUAÇÃO', description: '', mandatory: 'yes', type: 'input', subtype: '', mask: '', options: [] },
        { hash: 'university_entry_date', title: 'DATA DE INGRESSO NA UNIVERSIDADE', description: '', mandatory: 'yes', type: 'maskedInput', subtype: '', mask: '99/99/9999', options: [] },
        { hash: 'university_finish_date', title: 'DATA DE CONCLUSÃO DA GRADUAÇÃO', description: '', mandatory: 'yes', type: 'maskedInput', subtype: '', mask: '99/99/9999', options: [] },

      ],

    },


    vaccine_configuration: {
      model: 'jsonInputList',
      type: 'text',
      name: 'vaccine_configuration',
      label: 'Vacinas necessárias',
      show: 'title',
      list: [
        { label: 'Hash', name: 'hash', type: 'input' },
        { label: 'Título', name: 'title', type: 'input' },
        { label: 'Descritivo', name: 'description', type: 'richText' },
        {
          label: 'Obrigatório?', name: 'mandatory', default: 'yes', type: 'select', options: [
            { text: 'Sim', label: 'Sim', value: 'yes' },
            { text: 'Não', label: 'Não', value: 'no' },
          ]
        },


      ],

      defaultValue: [
        { hash: 'mmr', title: 'Duas doses de MMR (sarampo, caxumba e rubéola)', description: '', mandatory: 'yes' },
        { hash: 'hepatiteb', title: 'Três doses de vacina contra hepatite B', description: '', mandatory: 'yes' },
        { hash: 'antitetanica', title: 'Antitetânica', description: '', mandatory: 'yes' },
        { hash: 'covid19', title: 'Vacina contra Covid-19 - Esquema de duas doses, exceto Janssen que é dose única', description: '', mandatory: 'yes' },
      ],



    },

    register_steps: {
      model: 'jsonListMultiple',
      type: 'text',
      name: 'register_steps',
      label: 'Etapas de Matrícula',
      list: {
        hash: { label: 'Hash', name: 'hash', type: 'input' },
        title: { label: 'Título', name: 'title', type: 'input' },
      },
      defaultValue:
        [
          { hash: 'subscription', title: 'Dados pessoais' },
          { hash: 'jaleco', title: 'Jaleco' },
          { hash: 'photo', title: 'Foto do Crachá' },
          { hash: 'vaccine', title: 'Vacina' },
          { hash: 'documents', title: 'Documentos de matrícula' }

        ]
    },


    allow_medical_subscription_bank: {
      model: 'jsonInputList',
      type: 'text',
      name: 'allow_medical_subscription_bank',
      label: 'Bancos permitidos',
      show: 'title',
      list: [

        { label: 'Título', name: 'title', type: 'input' },
        { label: 'Tipo', name: 'type', type: 'input' },
        { label: 'Área', name: 'area', type: 'input' },
        { label: 'Subarea', name: 'subarea', type: 'input' },

        { label: 'Opções de Bancos', name: 'options', type: 'arrayIncluder' },

      ]
    },

    areas_allowed: {

      model: 'jsonInputListWithHeader',
      type: 'input',
      name: 'areas_allowed',
      label: 'Dados dos programas - Acesso direto',
      defaultValue: {
        header: [
          { label: 'Categoria', name: 'category', type: 'input' },
          { label: 'Área', name: 'area', type: 'input' },
          { label: 'Subárea', name: 'subarea', type: 'input' },
          { label: 'Cargo', name: 'cargo', type: 'input' },
          { label: 'Categoria Profissional', name: 'categoria_profissional', type: 'input' },
          { label: 'Programa', name: 'programa', type: 'input' },
          { label: 'Nome do Serviço', name: 'nome_servico', type: 'input' },
          { label: 'Número', name: 'numero', type: 'input' },
          { label: 'Anos', name: 'anos', type: 'input' },
          { label: 'Categoria', name: 'categoria', type: 'input' },

        ],
        data: []
      }






    },




    areas_allowed_pre_requisitos: {
      model: 'jsonInputListWithHeader',
      type: 'input',
      name: 'areas_allowed_pre_requisitos',
      label: 'Dados dos programas - Pré-requisitos',
      defaultValue: {
        header: [
          { label: 'Categoria', name: 'category', type: 'input' },
          { label: 'Área', name: 'area', type: 'input' },
          { label: 'Subárea', name: 'subarea', type: 'input' },
          { label: 'Cargo', name: 'cargo', type: 'input' },
          { label: 'Categoria Profissional', name: 'categoria_profissional', type: 'input' },
          { label: 'Programa', name: 'programa', type: 'input' },
          { label: 'Nome do Serviço', name: 'nome_servico', type: 'input' },
          { label: 'Número', name: 'numero', type: 'input' },
          { label: 'Anos', name: 'anos', type: 'input' },
          { label: 'Categoria', name: 'categoria', type: 'input' },


        ],
        data: []
      }



    },


    report_configuration: {

      model: 'jsonInputListWithHeader',
      type: 'input',
      name: 'report_configuration',
      label: 'Configuração de relatório',
      defaultValue: {
        header: [
          { label: 'Fonte', name: 'category', type: 'select', options: [{ label: 'Usuário', value: 'user' }, { label: 'Matrícula', value: 'subscription' }, { label: 'PRM', value: 'centro' }] },
          { label: 'Campo', name: 'field', type: 'input' },
          { label: 'Campo adicional', name: 'attach_field', type: 'input' },
          { label: 'Separador de campo adicional', name: 'attach_field_separator', type: 'select', options: [{ label: ' / ', value: ' / ' }, { label: ' - ', value: ' - ' }] },
          { label: 'Valor padrão', name: 'default', type: 'input' },
          { label: 'Título do campo', name: 'title', type: 'input' },


          { label: 'Remover acentos?', name: 'clean', type: 'select', options: [{ label: 'Sim', value: 'yes' }, { label: 'Não', value: 'no' }] },
          { label: 'Maiúsculo', name: 'uppercase', type: 'select', options: [{ label: 'Sim', value: 'yes' }, { label: 'Não', value: 'no' }] },
          { label: 'É uma data?', name: 'isDate', type: 'select', options: [{ label: 'Sim', value: 'yes' }, { label: 'Não', value: 'no' }] },

          { label: 'Somente números?', name: 'onlynumbers', type: 'select', options: [{ label: 'Sim', value: 'yes' }, { label: 'Não', value: 'no' }] },
          { label: 'Limite de caracteres', name: 'limit', type: 'input' },
          { label: 'Completar limite com', name: 'limit_complete', type: 'input' },
          { label: 'Substituir do campo', name: 'subscription_update_field', type: 'input' },
          { label: 'Valor do campo', name: 'subscription_update_value', type: 'input' },
          { label: 'Pelo Valor', name: 'subscription_update_new_value', type: 'input' },
        ],
        data: []
      }






    },

    allow_cancel: {
      model: 'selectSimple',
      name: 'allow_cancel',
      label: 'Permitir Cancelamento',



      options: [


        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },
    allow_reserve: {
      model: 'selectSimple',
      name: 'allow_reserve',
      label: 'Permitir Reserva Militar',



      options: [


        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },


  }

  const formSchemaProduct = {
    registration_product: {
      alias: 'title',
      model: 'selectApiDirect',
      name: 'registration_product',
      label: 'Produto de matrícula',
      endpoint: '/products',
    },
  }

  const formSchemaSchedule = {

    schedule: {
      model: 'jsonListMultiple',
      type: 'text',
      name: 'schedule',
      label: 'Cronograma',
      implementRealTimeInputUpdate: (values) => {

        values.map((item) => {

          item.text_date = mergeDateText(item.start_date, item.end_date);

        })


        return values;
      },
      list: {

        title: { label: 'Título', name: 'title', type: 'input' },

        start_date: { label: 'Data de início', name: 'start_date', type: 'datePickerHour' },
        end_date: { label: 'Data de Encerramento', name: 'end_date', type: 'datePickerHour' },
        text_date: { label: 'Data em texto', name: 'text_date', type: 'input' },
        active: { label: 'Ativo?', name: 'active', type: 'select', options: [{ text: 'Sim', value: 'yes' }, { text: 'Não', value: 'no' }] },
      },
      defaultValue:
        [
          { title: 'Inscrição', start_date: '', end_date: '', text_date: '', active: 'no' },
          { title: 'Prova', start_date: '', end_date: '', text_date: '', active: 'no' },
          { title: 'Resultado da Prova', start_date: '', end_date: '', text_date: '', active: 'no' },
          { title: 'Envio de documentação', start_date: '', end_date: '', text_date: '', active: 'no' },
          { title: 'Matrícula', start_date: '', end_date: '', text_date: '', active: 'no' },
          { title: 'Confirmação', start_date: '', end_date: '', text_date: '', active: 'no' },
        ]
    },


  };

  const formSchemaUpdate = {
    /* { logo: {
        model: 'singleImage',
        type: 'text',
        name: 'logo',
        label: 'Logo',
      },
      logo_adicional: {
        model: 'singleImage',
        type: 'text',
        name: 'logo_adicional',
        label: 'Logo Adicional',
      },
      background: {
        model: 'singleImage',
        type: 'text',
        name: 'background',
        label: 'Fundo',
      },
   
      image: {
        model: 'singleImage',
        type: 'text',
        name: 'image',
        label: 'Foto de Seleção',
      },
      data_image: {
        model: 'singleImage',
        type: 'text',
        name: 'data_image',
        label: 'Imagem da Data',
      },*/
    header_image: {
      model: 'singleImage',
      type: 'text',
      name: 'header_image',
      label: 'Imagem de capa'
    },
    /* fixed_image_header: {
       model: 'singleImage',
       type: 'text',
       name: 'fixed_image_header',
       label: 'Imagem de fundo Fixa'
     },*/


    ...formSchema



  };

  const formValidation: Record<string, ValidationSchema> = {

  };


  const modules = [
    (lineData: Record<string, any>) =>
      lineModules.updateUnique({
        lineData,
        title: 'Cronograma',
        formSchema: formSchemaSchedule,
        validationSchema: {},
        Icon: FaCalendarDay,
        endpoint,
      }),
    /* 
       (lineData: Record<string, any>) =>
         <button className="lineIcon" type="button" onClick={() => addModal({
           theme: 'whiteModal',
           title: "Configuração da Análise Currícular",
           content: <ShowCurriculumContent template={lineData?.curriculum_configuration || []} />
         })}><FaEye /> </button>,
       (lineData: Record<string, any>) =>
           FinanceReportByProjectModule({
             lineData,
     
           }), */
    (lineData: Record<string, any>) =>
      lineModules.updateUnique({
        lineData,
        title: 'Configurar processo seletivo',
        formSchema: formSchemaUpdate,
        validationSchema: {},
        endpoint,
      }),

    (lineData: Record<string, any>) =>
      lineModules.updateUnique({
        lineData,
        title: 'Configurar Análise Currícular',
        formSchema: formSchemaCurriculum,
        validationSchema: {},
        Icon: FaImages,
        endpoint,
      }),

    (lineData: Record<string, any>) =>
      lineModules.updateUnique({
        lineData,
        title: 'Configurar local da prova',
        formSchema: formSchemaLocalAddress,
        validationSchema: {},
        Icon: FaMap,
        endpoint,
      }),


    (lineData: Record<string, any>) =>
      lineModules.updateUnique({
        lineData,
        title: 'Configurar matrícula',
        formSchema: formSchemaMatricula,
        validationSchema: {},
        endpoint,
        Icon: FaIdBadge
      }),
    (lineData: Record<string, any>) =>
      lineModules.updateUnique({
        lineData,
        title: 'Produto da matrícula',
        formSchema: formSchemaProduct,
        validationSchema: {},
        endpoint,
        Icon: FaCertificate
      }),
    (lineData: Record<string, any>) =>
      FinanceReportByProjectModule({ lineData }),
    (lineData: Record<string, any>) =>
      isentionList({ lineData }),


    /*  (lineData: Record<string, any>) =>
        exportCurriculum({
          lineData,
   
        }),
  
  
    (lineData: Record<string, any>) =>
      SingleSaleReportModule({
        lineData,
  
      }), */


  ];

  if (user?.email === 'guilherme@encontrodigital.com.br') {
    modules.push((lineData: Record<string, any>) =>
      lineModules.delete({
        lineData,
        title,
        formSchema,
        validationSchema: formValidation,
        endpoint,
      }));
  }

  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () => CreateNewProjectModule({})
    ],
    bodyModules: modules
  };

  const { addToast } = useToast();

  const getFullReport = () => {
    addToast({ title: 'Gerando Relatório', type: 'success' });
    downloadit({ url: '/check-full-finance-report' })
  }

  return (
    <Container>
      <button style={{ alignSelf: 'center', margin: '10px auto' }} onClick={() => getFullReport()} className='defaultButtonMini'>Relatório de saques </button>
      <SearchComponent {...config} renderFunction={template} />
    </Container>
  );
};

export default EventsAdmin;
