import React from 'react';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import uuid from '../../../../core/components/UniqueHash';
import { useModal } from '../../../../../../hooks/Modal';
import FormComponent, { sanitizeDataObject } from '../../../../core/components/FormComponentUnique';
import { createSchemaByForm, IYup } from '../../../../../../utils/SchemaCreator';
import getValidationErrors from '../../../../../../utils/getValidationErrors';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';
import { useSearch } from '../../../../../../hooks/Search';
import { AxiosInstance } from 'axios';
import { FaPlus, FaUpload } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../../../../hooks/Auth';
import { FiFolder } from 'react-icons/fi';
import { useLoading } from '../../../../../../hooks/LoadingHook';

interface ICreateModule {
  lineData: Record<string, any>;
}

interface IParams {
  projectHash: string;
  eventHash: string;
}

export default function CreateModule({ lineData }: ICreateModule): JSX.Element {
  const { reloadSearchAll } = useSearch();
  const { addModal, removeModal } = useModal();
  const { addToast } = useToast();
  const { projectHash, eventHash } = useParams<IParams>();
  const { user } = useAuth();
  const key = uuid();
  const { addLoading, removeLoading } = useLoading();

  const setCallback = async (

  ): Promise<void> => {
    addLoading({ title: 'Carregando', key: 'loading' })

    try {

      const response = await api.post(`/create-judicial-folder/${lineData?._id?.toString()}`);

      if (response.status !== 200) {
        return addToast({ type: 'error', title: 'Erro ao salvar' });
      }

      removeModal(key);
      removeLoading('loading');

      await reloadSearchAll();

    } catch (err) {

      removeLoading('loading');
      return addToast({ type: 'error', title: err?.response?.data?.error || err?.response?.data?.message });
    }
  };

  const handleCreate = (): void => {
    const content = (
      <button className='defaultButtonReverse' onClick={() => setCallback()}>Criar pasta judicial</button>
    );
    if (content) {
      const keyModal = addModal({ title: 'Confirmar criação de pasta?', content, key, theme: "whiteModal" });
    }
  };

  const moduleCreate = (): JSX.Element => (
    user && user?.profile === 1 ? <button title='Criar documentação para recursos judicial' className="lineIcon" type="button" onClick={handleCreate}>
      <FiFolder />
    </button> : <></>
  );

  return moduleCreate();
}
