import React, { useState } from 'react';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';

import { useAuth } from '../../../../../../hooks/Auth';
import { FiSend } from 'react-icons/fi';
import Whatsapp from '../../../../../../components/Whatsapp';
import { authTitle, urlWebsite } from '../../../../../../config';
import { FaCheckCircle, FaMoneyBill } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import downloadit from '../../../../../../utils/download';
import { useModal } from '../../../../../../hooks/Modal';
import { useConfiguration } from '../../../../../../hooks/Configuration';
import { string } from 'yup';
import CheckboxInput from '../../../../core/components/Forms/CheckboxInput';
import { Form } from '@unform/web';
import SelectSimple from '../../../../core/components/Forms/SelectSimple';
import { ContentAvaliation } from './styles';
import { useSearch } from '../../../../../../hooks/Search';
import { rgb } from 'polished';

interface ICreateModule {
  lineData: Record<string, any>;
}

interface IProps {
  content: Record<string, any>;
  values: Record<string, any>;
  avaliation: Record<string, any>;
  setAvaliation: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  currentAvaliationIndex: number;
  setCurrentAvaliationIndex: React.Dispatch<React.SetStateAction<number>>;
  index: number;
  globalCount: number;
}

interface IPropsList {
  content: Record<string, any>;
  lineData: Record<string, any>;
}

interface IOption {
  id: string;
  value: string;
  label: string;
  sub_title?: string;
}


const VisualizeCurriculum: React.FC<IProps> = ({ content, values, index, globalCount, avaliation, setAvaliation, currentAvaliationIndex, setCurrentAvaliationIndex }) => {



  const [hugeImage, setHugeImage] = useState('')


  const optionsList = content?.categories ? [...content?.categories] : [];


  const calculateSub = (data, sub, index = 0, url) => {





    const points = parseFloat(content?.categories?.[index]?.points);
    let value = 0;
    let currentPoints = 0;

    /* key === url */
    Object.keys(data.images).map(key => {



      currentPoints = currentPoints + parseFloat(data.images[key]);

      if (currentPoints > points) {
        currentPoints = points;
      }

    });





    return currentPoints > points ? points.toFixed(2) : currentPoints.toFixed(2);


  }


  const calculate = (data, sub, index = 0) => {



    const points = parseFloat(content.points);
    let value = 0;



    Object.keys(data.avaliations).map(key => {

      const content = data.avaliations[key]?.avaliations || {};
      const points = parseFloat(data.avaliations[key]?.points);



      let currentPoints = 0;

      Object.keys(content).map(k => {
        const imgValue = content[k];


        currentPoints = currentPoints + parseFloat(imgValue);

        if (currentPoints > points) {
          currentPoints = points;
        }

      });

      value = value + currentPoints;


    })


    return value > points ? points.toFixed(2) : value.toFixed(2);


  }

  const updateAvaliation = (value) => {




    const getAvaliation = { ...avaliation };

    if (!getAvaliation[content.hash]) {
      getAvaliation[content.hash] = {
        points: content.points,
        current_value: 0,
        avaliations: {},
        subcategories: []
      }
    }

    if (getAvaliation?.[content.hash]?.avaliations) {
      Object.keys(getAvaliation[content.hash].avaliations).map(key => {

        if (getAvaliation?.[content.hash]?.avaliations?.[key]?.avaliations) {

          Object.keys(getAvaliation?.[content.hash]?.avaliations?.[key]?.avaliations).map(key2 => {

            if (key2 === value?.url) {
              delete getAvaliation?.[content.hash]?.avaliations?.[key]?.avaliations[key2];
            }

          })

        }

      })
    }

    Object.keys(getAvaliation[content.hash].avaliations).map(k => {

      if (getAvaliation?.[content?.hash]?.avaliations[k]?.avaliations?.[value?.url]) {
        delete getAvaliation[content.hash].avaliations[k]?.avaliations?.[value?.url];

        if (getAvaliation[content.hash].avaliations[k]?.avaliations.length < 1) {
          delete getAvaliation[content.hash].avaliations[k];
        }


      }

    })


    if (!getAvaliation[content.hash].avaliations?.[value.title]) {
      getAvaliation[content.hash].avaliations[value.title] = {
        points: value.points, avaliations: {
          [value?.url]: value?.value || '0.00'
        }
      }
    }
    else {
      if (!getAvaliation[content.hash].avaliations[value.title].avaliations) {
        getAvaliation[content.hash].avaliations[value.title].avaliations = { [value?.url]: value?.value || '0' }
      }
      else {
        getAvaliation[content.hash].avaliations[value.title].avaliations[value?.url] = value?.value || '0'
      }


    }


    const calculateValue = calculate(getAvaliation[content.hash], false);

    getAvaliation[content.hash].current_value = calculateValue;


    setAvaliation({ ...getAvaliation })


  }



  const updateAvaliationSub = (value, index) => {




    /* Qual a subcategoria */

    /* Qual o valor da opção selecionada */

    /* Gravar na avaliação */

    /* Atualizar o valor parcial */


    const getAvaliation = { ...avaliation };

    if (!getAvaliation[content.hash]) {
      getAvaliation[content.hash] = {
        points: content.points,
        current_value: 0,
        avaliations: {},
        subcategories: []
      }
    }

    if (getAvaliation?.[content.hash]?.avaliations) {
      Object.keys(getAvaliation[content.hash].avaliations).map(key => {

        if (getAvaliation?.[content.hash]?.avaliations?.[key]?.avaliations) {

          Object.keys(getAvaliation?.[content.hash]?.avaliations?.[key]?.avaliations).map(key2 => {

            if (key2 === value?.url) {
              delete getAvaliation?.[content.hash]?.avaliations?.[key]?.avaliations[key2];
            }
          })

        }

      })
    }


    Object.keys(getAvaliation[content.hash].avaliations).map(k => {

      if (getAvaliation?.[content?.hash]?.avaliations[k]?.avaliations?.[value?.url]) {
        delete getAvaliation[content.hash].avaliations[k]?.avaliations?.[value?.url];

        if (getAvaliation[content.hash].avaliations[k]?.avaliations.length < 1) {
          delete getAvaliation[content.hash].avaliations[k];
        }


      }

    })


    if (!getAvaliation[content.hash].avaliations?.[value.title]) {
      getAvaliation[content.hash].avaliations[value.title] = {
        points: value.points, avaliations: {
          [value?.url]: value?.value || '0.00'
        }
      }
    }
    else {
      getAvaliation[content.hash].avaliations[value.title].avaliations[value?.url] = value.value
    }


    if (!getAvaliation[content.hash].sub_values) {
      getAvaliation[content.hash].sub_values = [];
    }



    if (!getAvaliation[content.hash].sub_values[index]) {
      getAvaliation[content.hash].sub_values[index] = {

        points: content?.categories?.[index]?.points,
        value: 0,
        images: {
          [value.url]: value?.value || '0.00'
        }
      };
    }
    else {
      getAvaliation[content.hash].sub_values[index].images[value.url] = value.value
    }


    getAvaliation[content.hash].sub_values[index].value = calculateSub(getAvaliation[content.hash].sub_values[index], true, index, value.url);





    let finalPoint = 0;

    getAvaliation?.[content.hash]?.sub_values.map(p => {
      finalPoint = finalPoint + parseFloat(p?.value || '0')
    })



    getAvaliation[content.hash].current_value = content.points <= finalPoint ? content.points : finalPoint;


    setAvaliation({ ...getAvaliation })


  }


  if (content.show_subcategories === 'no') {

    return <ContentAvaliation style={{ color: '#333', display: currentAvaliationIndex === index ? 'flex' : 'none' }} >
      <div className='contentAvaliationScore'>
        <h2>{content.title} </h2><nav>Nota : {avaliation?.[content?.hash]?.current_value ? parseFloat(avaliation?.[content?.hash]?.current_value).toFixed(2) : "0"}</nav>

      </div>
      <div className='separator' />

      {values?.uploads?.length < 1 ? <h2 style={{ color: '#777', margin: '25px 0px' }}>Nenhum documento adicionado</h2> : <></>}

      {values?.uploads?.map((image, imageIndex) => {

        const options: Array<IOption> = [];

        {
          content?.categories?.map((av, index) => {
            options.push({ value: index, id: index, label: av.title });
          })
        }

        return <div style={{ background: '#eee', padding: '15px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: '15px', borderRadius: '10px' }}>
          <p className='title'>{imageIndex + 1}. Documento</p>
          {image?.url?.toLowerCase()?.indexOf('.pdf') >= 0 ? <a href={`${urlWebsite}/${image.url}`} download><img key={image.url} src={`/apoio/pdf.png`} style={{ width: '100%', cursor: 'pointer', maxWidth: '150px' }} /> </a> : <a href={`${urlWebsite}/${image.url}`} download><img key={image.url} src={`${urlWebsite}/${image.url}`} style={{ width: '100%', cursor: 'pointer', maxWidth: '200px' }} /></a>}
          <p className='title'>Avaliar</p>
          <select onChange={(e) => {
            updateAvaliation({ ...optionsList[e.target.value], url: image.url })

          }} name={image.url} >
            <option value={-1}>Selecionar</option>
            {options.map(opt => {



              return <option selected={avaliation?.[content?.hash]?.avaliations?.[opt.label]?.avaliations?.[image.url] ? true : false} key={`${image.url}${opt.value}`} value={opt.value}>{opt.label}</option>
            })}
          </select>
        </div>
      })}

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>

        <button onClick={() => { setCurrentAvaliationIndex(currentAvaliationIndex - 1); document.getElementById('modalContent')?.scrollTo(0, 0) }} style={{ display: currentAvaliationIndex < globalCount && currentAvaliationIndex > 0 ? 'flex' : 'none' }} className='nextAvaliationReturn'>Voltar</button>

        <button className='nextAvaliation' onClick={() => { setCurrentAvaliationIndex(state => state + 1); document.getElementById('modalContent')?.scrollTo(0, 0) }}>Próximo</button>
      </div>
      <div className='hugeImage' style={{ display: hugeImage ? 'flex' : 'none' }}>
        <button className='nextAvaliation' onClick={() => setHugeImage('')}>Fechar</button>
        <div>


          <img src={hugeImage} style={{ width: '100%' }} />
        </div>

      </div>
    </ContentAvaliation>
  }



  return <ContentAvaliation style={{ color: '#333', display: currentAvaliationIndex === index ? 'flex' : 'none' }} >
    <div className='contentAvaliationScore'>
      <h2>{content.title} </h2><nav>Nota : {avaliation?.[content?.hash]?.current_value ? parseFloat(avaliation?.[content?.hash]?.current_value).toFixed(2) : "0"}</nav>

    </div>
    <div className='separator' />

    {content.categories.map((category, categoryIndex) => {


      const key = category.hash;
      return <>
        {values?.[key]?.length < 1 ? <h2 style={{ color: '#777', margin: '25px 0px' }}>Nenhum documento adicionado</h2> : <></>}

        {values?.[key]?.map((image, imgIndex) => {

          const options: Array<IOption> = [];


          category?.subcategories?.map((av, index) => {
            options.push({ value: index, id: index, label: av.title });
          })






          return <>
            {imgIndex < 1 ? <p style={{ padding: '10px', fontSize: '12px' }}><strong> Subcategoria:</strong><br />{category.title} </p> : <></>}
            <div style={{ background: '#eee', padding: '15px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: '15px', borderRadius: '10px' }}>


              <p className='title'>{categoryIndex + 1}. Documento</p>

              {image?.url?.toLowerCase()?.indexOf('.pdf') >= 0 ? <a href={`${urlWebsite}/${image.url}`} download><img key={image.url} src={`/apoio/pdf.png`} style={{ width: '100%', cursor: 'pointer', maxWidth: '150px' }} /></a> : <a href={`${urlWebsite}/${image.url}`} download><img key={image.url} src={`${urlWebsite}/${image.url}`} style={{ width: '100%', cursor: 'pointer', maxWidth: '200px' }} /></a>}
              <p className='title'>Avaliar</p>
              <select onChange={(e) => {
                updateAvaliationSub({ ...category.subcategories[e.target.value], url: image.url }, categoryIndex)

              }} name={image.url} >




                <option value={-1}>Selecionar</option>
                {options.map(opt => {

                  return <option selected={avaliation?.[content?.hash]?.avaliations?.[opt.label]?.avaliations?.[image.url] ? true : false} key={`${image.url}${opt.value}`} value={opt.value}>{opt.label}</option>
                })}
              </select>
            </div>

          </>
        })}

      </>
    })}





    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>

      <button onClick={() => setCurrentAvaliationIndex(currentAvaliationIndex - 1)} style={{ display: currentAvaliationIndex < globalCount && currentAvaliationIndex > 0 ? 'flex' : 'none' }} className='nextAvaliationReturn'>Voltar</button>

      <button className='nextAvaliation' onClick={() => { setCurrentAvaliationIndex(state => state + 1); document.getElementById('modalContent')?.scrollTo(0, 0) }}>Próximo</button>
    </div>
    <div className='hugeImage' style={{ display: hugeImage ? 'flex' : 'none' }}>
      <button className='nextAvaliation' onClick={() => setHugeImage('')}>Fechar</button>
      <div>


        <img src={hugeImage} style={{ width: '100%', }} />
      </div>

    </div>
  </ContentAvaliation>

}


const VisualizeCurriculumList: React.FC<IPropsList> = ({ content, lineData }) => {
  const [avaliation, setAvaliation] = useState<Record<string, any>>(lineData?.curriculum_avaliation || {});

  const [currentAvaliationIndex, setCurrentAvaliationIndex] = useState(0);
  const { addToast } = useToast();
  const { removeModal } = useModal();
  const { reloadSearchAll } = useSearch();



  const globalValue = Object.keys(avaliation).reduce((prev, key) => {


    prev = prev + parseFloat(avaliation?.[key]?.current_value);


    return prev;

  }, 0)

  const finishAvaliation = async () => {

    try {
      const response = await api.post(`/finish_curriculum_avaliation/${lineData._id}`, {
        curriculum_points: parseFloat(globalValue.toFixed(2)),
        avaliation: avaliation,
      });

      addToast({ title: 'Atualizado com sucesso' });
      removeModal('curriculum_avaliation');
      reloadSearchAll();
      return;
    }
    catch (err) {
      console.log(err);
      addToast({ title: err?.response?.data?.message || 'Erro ao salvar avaliação' })
    }

  }

  const fields: Array<JSX.Element> = [];

  content.map((item, index) => {

    const items = lineData?.curriculum?.[item.hash] || [];



    fields.push(<VisualizeCurriculum key={item.hash} globalCount={content.length} index={index} setCurrentAvaliationIndex={setCurrentAvaliationIndex} currentAvaliationIndex={currentAvaliationIndex} setAvaliation={setAvaliation} avaliation={avaliation} content={item} values={items} />);

  })

  return <>
    <div style={{ width: '100%', maxWidth: '500px', margin: '10px auto' }}>
      <h2 style={{ background: '#41517E', color: '#fff', textAlign: 'left', borderRadius: '10px', width: '100%', padding: '15px', margin: '0px auto' }}>Nota Global: {
        globalValue.toFixed(2)
      }</h2>
    </div>

    {fields}

    <button onClick={() => { finishAvaliation() }} style={{ display: currentAvaliationIndex === content.length ? 'flex' : 'none' }} className='nextAvaliation'>Finalizar avaliação</button>

  </>
}


export function useAvaliateCurriculum({ lineData }) {

  const { projectHash } = useParams<Record<string, any>>();
  const { addToast } = useToast();
  const { addModal } = useModal();
  const { configuration } = useConfiguration();



  const gerarRelatorioFinanceiro = async () => {

    const content = lineData?.area === 'ORTOPEDIA E TRAUMATOLOGIA' && lineData?.project_id === 'hmv-residencia-medica' ? [...configuration?.curriculum_configuration_especial || []] : lineData?.category?.toUpperCase() === 'PRÉ-REQUISITOS' ? configuration.curriculum_configuration_pre_requisitos ? [...configuration.curriculum_configuration_pre_requisitos] : [] : configuration.curriculum_configuration ? [...configuration.curriculum_configuration] : [];




    addModal({
      title: 'Avaliar Currículo',
      content: <VisualizeCurriculumList content={content} lineData={lineData} />,
      theme: 'whiteModal',
      key: 'curriculum_avaliation',
      closeButton: false
    })


  }

  const moduleUpdate = (): JSX.Element => (
    projectHash ? <button className='defaultButtonMini' style={{ background: lineData?.curriculum_status === 'yes' ? 'rgb(0,150,0)' : 'rgb(250,50,0)', color: '#fff' }} type="button" onClick={() => gerarRelatorioFinanceiro()}>
      {lineData?.curriculum_status === 'yes' ? 'Avaliado' : 'Avaliar'}
    </button> : <><button className='defaultButtonMini' style={{ background: '#333', color: "#ccc" }}>Aguarde o período de avaliação</button></>
  );

  return moduleUpdate();
}
